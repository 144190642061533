<template>
  <div class="main">
    <img src="@/assets/login.png" alt="" class="login-image" />
    <div class="login-box">
      <div class="login-box-inner">
        <h2>欢迎登录</h2>
        <el-form :model="form" :rules="rules" ref="form">
          <el-form-item prop="username">
            <el-input
              v-model="form.username"
              placeholder="请输入账号"
            ></el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input
              type="password"
              v-model="form.password"
              placeholder="请输入密码"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <!-- <el-checkbox v-model="form.remember">记住密码</el-checkbox> -->
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              class="login-btn"
              native-type="submit"
              @click.prevent="submit"
              :loading="loading"
              >登录</el-button
            >
          </el-form-item>
          <div>
            还没有账号？<el-link
              type="primary"
              @click="
                () => {
                  $router.push('/register')
                }
              "
              >去申请</el-link
            >
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
import { setToken } from '@/utils/auth'
export default {
  data() {
    return {
      loading: false,
      form: { username: '', password: '', remember: false },
      rules: {
        username: [
          {
            required: true,
            message: '请输入登录账号',
            trigger: 'blur'
          }
        ],
        password: [
          {
            required: true,
            message: '请输入登录密码',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  methods: {
    submit() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.loading = true
          this.$request
            .post('/login', {
              loginName: this.form.username,
              passWord: this.form.password
            })
            .then((ret) => {
              this.loading = false
              setToken(
                btoa(
                  JSON.stringify({
                    CompanyId: 0,
                    SystemId: 1,
                    Token: ret.data.token,
                    UserId: ret.data.id
                  })
                )
              )
              window.localStorage.setItem('userInfo', JSON.stringify(ret.data))
              this.$router.replace({ path: '/' })
            })
        } else {
          return false
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.main {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  // height: 100%;
  // width: 100vh;
  display: flex;
  > .login-image {
    width: 50%;
    height: 100%;
  }
  > .login-box {
    height: 100%;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    > .login-box-inner {
      width: 50%;
      max-width: 400px;
      > h2 {
        text-align: center;
        color: rgba(24, 59, 86, 1);
      }
      .login-btn {
        width: 100%;
      }
    }
  }
}
</style>